import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom'
import { baseUrl } from '../config/config';
import ApiLoader from './ApiLoader';
// import { Dropdown } from 'react-bootstrap'
import { Navbar, Nav } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import logo from '../assets/images/logo.png';
import dots from '../assets/images/dots.png';
import girl from '../assets/images/banner_girl_2.png';
import boy from '../assets/images/boy-image.svg';
import login_banner from '../assets/images/login-banner.png';
import admin from '../assets/images/admin-login.svg';
import teacher from '../assets/images/teacher-login.svg';
import contact_img from '../assets/images/contact-image.png';
import email from '../assets/images/email.svg';
import { redirection } from '../config/routings';
// import address from '../assets/images/pin.svg';

function Home() {

  const history = useHistory();
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    
    axios.get(baseUrl + 'homedata')
      .then((res) => {
        setData(res.data?.data);
        setLoader(false);
        console.log(res.data);
      })
  }, [])

  return (
    <React.Fragment>
      {loader && <ApiLoader />}
    <div className="navbar-border">
      <span className="red"></span>
      <span className="blue"></span>
      <span className="black"></span>
    </div>
    <Navbar collapseOnSelect expand="lg" className="home-navbar">
      <Container>
        <Navbar.Brand href="">
          <img src={logo} alt="logo-img" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href={redirection.login} className='login-btn blue'>Login as Teacher</Nav.Link>
              <Nav.Link href='https://qa-listen.dynamicwebsite.co.in/backend/public/' className='login-btn blue'>Login as Admin</Nav.Link>
            </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <div className="home-banner">
        <div className="home-left">
            <div className="home-left-text">
              <img src={dots} alt="" className='dots-image'/>
              <div className="title-small blue-text">
                {/* LOREM IPSUM DOLORE */}
                {data?.banner_text1}
              </div>
              <div className="title">
                  <h1>
                    <span className='black-text'>
                      {data?.banner_text2}
                      {/* Lorem Ipsum Dolor */}
                    </span>
                    <span className='blue-text'>
                      {data?.banner_text3}
                      {/* Et Dolore Magna */}
                    </span>
                  </h1>
              </div>
              <div className="undertitle-text">
                {data?.banner_text4}
                {/* Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. */}
              </div>
            </div>
        </div>
        <div className="home-right">
          <img src={girl} alt="girl-img" />
        </div>
    </div>
    <div className="about-us-section">
        <div className="container">
          <div className="about-us-title">
              <h2>About Us</h2>
          </div>
          <div className="about-content">
              <div className="about-left">
                  <img src={boy} alt="no Img" />
              </div>
              <div className="about-right">
              
                <div className="about-right-title">
                  {/* Adipiscing Elit Sed Diam Nonummy */}
                </div>
                <div dangerouslySetInnerHTML={{__html:data?.aboutus_text}}></div>
                {/* <div className="about-right-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                  </p> 
                  <p>
                    Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                  </p> 
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
                  </p>
                </div> */}
              </div>
          </div>
        </div>
    </div>
    <div className="home-login-sec">
      <img src={login_banner} className='home-banner-img' alt="" />
      <div className="login-btn-cover">
        <button class="red" onClick={() => history.push(redirection.login)} >  
          <img src={teacher} alt="" />
          Login as Teacher
        </button>
        <button class="dark-blue" onClick={() => window.location.replace('https://listenportal.com/admin/')}>
          <img src={admin} alt="" />
          Login as Admin
        </button>
      </div>
    </div>
    <div className="contact-sec">
      <div className="contact-sec-content">
          <div className="contact-left">
              <div className="contact-left-content">
                <div className="contact-title">
                    <h2>
                      Contact Us
                    </h2>
                </div>
                <div className="contact-info">
                    <div className="contact-panel">
                        <div className="contact-img">
                          <img src={email} alt="email-icon" />
                        </div>
                        <div className="contact-text">
                          <div className="contact-text-heading">
                            EMAIL
                          </div>
                          <div className="contact-text-value">
                          <a href = {`mailto:${data?.contact_email}`} >{data?.contact_email}</a>
                          {/* <a href={`mailto:${data?.contact_email}`} > */}
                          </div>
                        </div>
                    </div>
                    
                </div>
              </div>
          </div>
          <div className="contact-right">
            <img src={contact_img} alt="" />
          </div>
      </div>
    </div>
    <div className="copyright-sec">
        <div className="copyright-text">
          Copyright © {(new Date().getFullYear())} Visionary Technologies, LLC. All Rights Reserved
        </div>
    </div>
    </React.Fragment>
  )
}

export default Home