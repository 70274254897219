import React, { useState } from 'react';
import {baseUrl} from '../../config/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/sass/_login.sass';
import logo from '../../assets/images/logo.png';
import computer from '../../assets/images/computer-login.svg';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { redirection } from '../../config/routings';

function Login() {
  const history = useHistory();
  const cookies = new Cookies();
  const [loader, setLoader] = useState(false);
  const [credentialError, setCredentialError] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [termConditionCheck, settermConditionCheck] = useState( cookies.get('termsConditionChk') )
  
  const [data, setData] = useState({
    email: "",
    password: ""
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(() => true);
    let error = false;
    if (data.password === "" || data.confirmPassword === "") {
      error = true;
    }
    if (data.email !== "") {
      if (!new RegExp(/[a-zA-Z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-Z]{2,15}/g).test(data.email)) {
      // if (!new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i).test(data.email)) {
        setIsEmailValid(() => false );
        error = true;
      }
    }
    if (error) {
      return;
    }
    setLoader(true);
    axios.post(baseUrl+'login', data)
			.then(response => {
        if(response.data.error) {
          setCredentialError(true);
          setLoader(false);
        }else{
          localStorage.setItem('token', response.data.access_token)
          cookies.set('termsConditionChk', 'true')
          history.push(redirection.dashboard)
        }
			}).finally(() =>  setLoader(false))
  }

  return (
    <div className="register-outer">
      <div className="register-box">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="logo-panel" onClick={() => history.push('/')}>
                <img src={logo} alt="test-img" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 order-lg-1 order-2">
              <form onSubmit={handleSubmit}>
                <div className="form-box">
                  <div className="register-title">
                    <h1>Login as Teacher</h1>
                  </div>
                  {credentialError && <div className="alert alert-danger"> <strong>Invalid Credentials!</strong></div>}
                  <div className="register-form">
                    <div className="form-group">
                      <label>Email ID</label>
                      <input
                        name="email"
                        className="form-control"
                        placeholder="Email ID"
                        onChange={(e) => setData({ ...data, email: e.target.value })}
                      />
                      {submitted && data.email === "" && <p className="text-danger">Email is required.</p>}
                      {submitted && !isEmailValid && data.email !== "" && <p className="text-danger">Please enter a valid email address.</p>}
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={(e) => setData({ ...data, password: e.target.value })}
                      />
                      {submitted && data.password === "" && <p className="text-danger">Password is required.</p>}
                      <br/>

                      {
                        cookies.get('termsConditionChk') !== 'true' ?
                        <> 
                          <input 
                            type="checkbox"
                            name="termConditionCheck"
                            required
                            onClick={(e) => settermConditionCheck(!termConditionCheck)}
                          /> <nbsp/>
                          Proceed only if you agree to the <Link to={redirection.termsAndCondition}>Terms and Conditions</Link>
                          {/* {submitted && cookies.get('termsConditionChk') !== 'true' && <p className="text-danger">Checkbox is required.</p> } */}
                        {/* {submitted && termConditionCheck===false && <p className="text-danger">Checkbox is required.</p> } */}
                        </>
                        : 
                        <> 
                          <input 
                            type="checkbox"
                            name="termConditionCheck"
                            checked
                            onClick={(e) => settermConditionCheck(!termConditionCheck)}
                          /> <nbsp/>
                          Proceed only if you agree to the <Link to={redirection.termsAndCondition}>Terms and Conditions</Link>
                        </>
                                              
                      }  

                      <div className="forgot-link">
                        <Link to={redirection.forgotPassword}>Forgot Password?</Link>
                      </div>
                    </div>
                   
                    <div className="btn-cover">
                      <button type="submit" className="submit-btn btn">
                      {loader ? <i className="fa fa-spinner fa-spin" /> : 'Login'}
                      </button>
                    </div>
                  </div>
                </div>
                </form>
            </div>
            <div className="col-lg-7 order-lg-2 order-1">
              <div className="right-panel-img">
                <img src={computer} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
